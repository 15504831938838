<template>
  <div class="flp-panel pan1 top-pan">
    <div v-for="(tile, i) in tiles" :key="i" class="pan-content pan1-content">
      <div class="flp-panel-title">{{ tile.primaryTitle }}</div>
      <div v-if="!tile.loading" class="flp-panel-amt">
        <b-numero
          :value="tile.primaryValue"
          :is-infinity="tile.primaryIsInfinity"
          :type="tile.primaryValueType"
          :is-campaign-page="true"
        />
      </div>
      <div v-if="!tile.loading">
        <b-numero
          :value="tile.secondaryValue"
          :is-infinity="tile.secondaryIsInfinity"
          :type="tile.secondaryValueType"
          :use-same-font-size="true"
          :title="tile.secondaryTitle"
          :is-campaign-page="true"
          class="flp-panel-amt-label"
        />
      </div>
      <b-loading-spinner v-if="tile.loading" class="txt-center" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CampaignStatsTiles',

  components: {
    BNumero: () => import(/* webpackChunkName: "b-numero" */ '~/components/elements/b-numero.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },

  props: {
    tiles: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.flp-panel {
  padding: 30px 30px 35px 30px;
  display: inline-block;
  color: #444;
  border-radius: 0px;
  position: relative;
  z-index: 2;
}
.pan1 {
  display: inline-flex;
  justify-content: space-between;
}
.pan1 {
  width: 100%;
}

.top-pan .flp-panel-amt {
  margin: 8px 0px;
}

.pan1-content {
  display: inline-block;
}
.flp-panel-title {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #60697b;
}

::v-deep .intip4 {
  span {
    font-size: 35px;
    font-weight: 400;
    line-height: 1.1em;

    &.big {
      font-size: 35px;
    }

    &.small {
      font-size: 20px;
    }

    &.same {
      position: absolute;
      margin: -8px 0px 0px 0px;
      color: #60697b;
      font-size: 13px;
      font-weight: 500;
      width: 95px;
    }
  }
}
::v-deep .intip4 {
  &.flp-panel-amt-label {
    margin: 18px 0px 0px 0px !important;
  }

  span {
    &.same {
      color: #60697b;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.txt-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
  right: 18px;
}

::v-deep .intip4[data-tool-tip]:hover::after {
  bottom: -160% !important;
  left: 50% !important;
}
</style>
